<template>
  <div :class="$style.root">
    <VForm v-model="valid"  ref="form" @submit.prevent="onSave">
      <CounterpartyCard label="Оставьте ответы на вопросы">
        <div :class="$style.item" v-for="question in fields" :key="question.code">
          <SurveyValues
              :label="question.label"
              :hint="question.hint"
              :values="question.values"
              :type="question.type"
              :rules="[...rules.required, ...rules.lengthLimit]"
              :options="question.options"
              :value-for-comment="question.valueForComment"
          />
        </div>
      </CounterpartyCard>

    </VForm>
    <VFooter v-if="diff && !isReviewer && !isSpectator" app>
      <VProgressLinear v-if="pending" absolute indeterminate color="primary darken-2" :class="$style.progress" />
      <div class="py-3 grow">
        <div class="float-left">
          <VBtn color="primary" class="mr-2" depressed :disabled="pending || !valid" @click="onSave">Отправить</VBtn>
          <VBtn color="secondary" class="mr-2" depressed :disabled="pending" @click="onReset">Сбросить</VBtn>
        </div>
      </div>
    </VFooter>
  </div>
</template>

<script>
import {isArray, isEmpty} from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import {ROLES} from '@/store/user/enums';
import CounterpartyCard from '@/components/pkosmr/CounterpartyCard/CounterpartyCard.vue';
import SurveyValues from '@/components/pkosmr/SurveyDetail/components/SurveyValues.vue';

export default {
  name: 'SurveyDetail',
  components: {
    CounterpartyCard,
    SurveyValues,
  },
  props: {
    id: { type: String, required: true },
    values: { type: Array, default: () => [] },
  },
  data() {
    return {
      valid: true,
      fields: JSON.parse(JSON.stringify(this.values)),
      rules: {
        required: [
          v => {
            if (isArray(v) && isEmpty(v)) return 'Обязательное поле';
            if (!v) return 'Обязательное поле';
            return true;
          }
        ],
        lengthLimit: [
          v => {
            if (v && v.length > 300) return 'Максимальная длина 300 символов';
            return true;
          }
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      pending: 'pkosmr/pending',
      hasRole: 'user/hasRole',
      user: 'user/current',
    }),
    isReviewer() {
      return this.hasRole([ROLES.REVIEWER, ROLES.FIRST_REVIEWER, ROLES.SECOND_REVIEWER, ROLES.LAWYER, ROLES.APPROVER], 'pkosmr');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkosmr');
    },
    diff() {
      return JSON.stringify(this.values) !== JSON.stringify(this.fields);
    },
  },
  methods: {
    ...mapActions({
      saveSurveyForm: 'pkosmr/saveSurveyForm',
    }),
    onSave: function() {
      const id = this.id;
      const fields = this.fields;
      this.saveSurveyForm({ id, fields }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Спасибо! Ваши ответы успешно сохранены',
        });
        this.$router.push({ name: 'pkosmr/CounterpartyListView' });
      });
    },
    onReset: function() {
      this.fields = JSON.parse(JSON.stringify(this.values));
    },
  },
  watch: {
    diff: {
      immediate: true,
      handler: function(value) {
        if (value) {
          this.timer = setInterval(this.onSave, 5 * 60 * 1000) // 5 мин
        } else {
          clearInterval(this.timer);
        }
      }
    },
    values: {
      handler: function() {
        this.onReset();
      }
    },
  }
}
</script>

<style module lang="scss">
.root {

}
.item {
  padding: 20px 16px;
  margin: 0 -16px;
  & + & {
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
  }
}
.value {
  padding: 10px 16px;
  margin: 0 -16px;
}
.title {
  font-weight: bold;
  font-size: 14px;
  color: #212121;
}
.progress {
  top: 0;
}
.product {
  padding: 10px 16px;
  margin: 0 -16px;
  .left {
    float: left;
    padding-right: 10px;
    .index {
      font-weight: bold;
      padding: 9px 0;
    }
  }
  .right { float: right }
  &:first-child .right { margin-top: 22px; }
}
.control {
  float: right;
  min-height: 40px;
  margin-left: 10px;
  position: relative;
  z-index: 1;
}
.label {
  pointer-events: none;
}
</style>
